* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --transition: .25s;
  --spark: 1.5s;
  --speed: 3s;
}

html {
  scroll-behavior: smooth;
  background-color: #f6f9fc;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #425466;
  font-family: Playfair Display, serif;
  font-size: 1.4rem;
  overflow-x: hidden;
}

.mb-3 {
  margin-bottom: .8rem;
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(-5deg);
  }

  20% {
    transform: rotate(3deg);
  }

  30% {
    transform: rotate(-2deg);
  }

  40% {
    transform: rotate(1deg);
  }

  50% {
    transform: rotate(0);
  }

  60% {
    transform: rotate(-1deg);
  }

  70% {
    transform: rotate(2deg);
  }

  80% {
    transform: rotate(-3deg);
  }

  90% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes flip {
  to {
    rotate: 360deg;
  }
}

@keyframes rotate {
  to {
    transform: rotate(90deg);
  }
}

.slide-from-bottom {
  opacity: 1 !important;
  bottom: 0 !important;
}

.slide-in {
  opacity: 1 !important;
  transform: translate(0) !important;
}

.header {
  backdrop-filter: blur(20px);
  z-index: 9999;
  background-color: #ffffffc9;
  width: 100%;
  position: fixed;
}

.header-container {
  justify-content: space-between;
  align-items: center;
  height: 6.4rem;
  padding: 0 4.8rem;
  display: flex;
}

.banner {
  text-align: center;
  background-color: #dd4e4e;
  padding: .5rem;
}

.banner a {
  color: #fff;
  text-decoration: none;
}

body.popup-active {
  overflow: hidden;
}

.popup {
  display: none;
}

.popup[data-active="true"] {
  z-index: 99999;
  background-color: #f4f1f1;
  border-radius: 8px;
  flex-direction: column;
  width: 400px;
  height: 400px;
  padding: 1.6rem;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px #0000001a;
}

.popup-header {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.popup-header h2 {
  text-align: center;
  flex-grow: 1;
}

.popup-closeBtn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-100%);
}

.popup-content {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  display: flex;
}

.popup-content-section {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  display: flex;
}

.popup-content-section-times {
  text-align: left;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.6rem;
  display: grid;
}

.time-title {
  justify-self: start;
}

.time-value {
  justify-self: end;
}

.spacer {
  background-color: #ccc;
  width: 25%;
  height: 1px;
}

.overlay {
  z-index: 999;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay[data-active="true"] {
  pointer-events: none;
  display: block;
}

.logo {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.logo-img {
  height: 3.2rem;
}

.logo-title {
  text-transform: uppercase;
  color: #ba945c;
  font-size: 1.6rem;
}

.main-nav-list {
  align-items: center;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.main-nav-link:is(:link, :visited) {
  color: #425466;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all, all .3s ease-in-out;
}

.main-nav-link:is(:hover, :active, :focus-visible) {
  color: #ba945c;
}

.main-nav-rsvp {
  color: #fff;
  background-color: #a0b9a2;
  border-radius: 100px;
  padding: 1rem 1.6rem;
  text-decoration: none;
  transition: all .2s ease-in-out;
  box-shadow: inset 0 .05em #ffffff59, inset 0 -.05em #0000002e;
}

.main-nav-rsvp:is(:hover, :focus-visible) {
  color: #edf3e7;
  background-color: #a0b9a2;
}

.mobile-menu {
  transition: all 1.5s all ease-in-out;
  display: none;
}

.mobile-menu-btn {
  display: none;
}

.highlight {
  color: #ba945c !important;
}

.rsvp-float-bar {
  background-color: #333;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 58px;
  padding-left: 1.2rem;
  padding-right: 2.4rem;
  display: flex;
  position: fixed;
  top: 100dvh;
  left: 50vw;
  transform: translate(-50%, -120%);
}

.test-bar {
  --cut: .1em;
  --bg: radial-gradient(40% 50% at center 100%, #5e5e5e80, transparent), radial-gradient(80% 100% at center 120%, #b3b3b31a, transparent), #5e5e5e;
  background: var(--bg);
  white-space: nowrap;
  scale: calc(1 + (var(--active) * .1));
  cursor: pointer;
  border: 0;
  border-radius: 100px;
  place-items: center;
  width: auto;
  height: 60px;
  padding: .9em 1.3em;
  font-size: 2rem;
  text-decoration: none;
  display: grid;
  position: fixed;
  top: 100dvh;
  left: 50vw;
  overflow: hidden;
  transform: translate(-50%, -120%);
  box-shadow: inset 0 .05em gray, inset 0 -.05em #1a1a1a, 0 .3em 30px 2px #70707076;
}

.spark {
  animation: flip calc(var(--spark) * 2) infinite steps(2, end);
  border-radius: 100px;
  position: absolute;
  inset: 0;
  overflow: hidden;
  rotate: none;
  mask: linear-gradient(#fff, #0000 50%);
}

.spark:before {
  content: "";
  aspect-ratio: 1;
  z-index: -1;
  translate: -50% -15%;
  rotate: 0;
  opacity: calc((var(--active))  + .4);
  opacity: 1;
  background: conic-gradient(from 0deg, transparent 0 340deg, #f5c67f 360deg);
  transition: opacity (var(--transition));
  animation: rotate var(--spark) linear infinite both;
  width: 250%;
  position: absolute;
  inset: 0 auto auto 50%;
  transform: rotate(-90deg);
}

.spark:after {
  content: "";
  inset: var(--cut);
  border-radius: 100px;
  position: absolute;
}

.backdrop {
  inset: var(--cut);
  background: var(--bg);
  border-radius: 100px;
  position: absolute;
}

.text {
  letter-spacing: .01ch;
  color: #0000;
  background: linear-gradient(90deg, #d5a153, #bd8a3d) text;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
  translate: 2% -6%;
}

.arrow-top-right, .bus-icon {
  color: #bd8a3d;
}

.rsvp-float-container {
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  font-size: 1.6rem;
  display: flex;
}

.rsvp-float-message {
  color: #fff;
}

.rsvp-float-btn {
  color: #fff;
  background-color: #ba945c;
  border-radius: 86px;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
}

.hero-container {
  background-image: url("hero-lg.a8274308.webp");
  background-position: 30% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100dvh;
}

.schedule {
  color: #fff;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.schedule-img-box {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.schedule-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.schedule-event {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  display: flex;
}

.schedule-event h2 {
  text-transform: uppercase;
  letter-spacing: .1rem;
  text-wrap: balance;
  text-align: center;
  font-size: 4.4rem;
}

.schedule-date {
  text-align: center;
  text-wrap: balance;
  text-transform: uppercase;
  font-size: 2.4rem;
}

.schedule-location, .schedule-description {
  text-align: center;
  text-wrap: balance;
  font-size: 2rem;
}

.schedule-event.welcome {
  background-color: #d19476cc;
}

.schedule-event.wedding {
  background-color: #827d7ccc;
}

.schedule-event a:is(:link, :visited) {
  color: #fff;
  text-decoration: none;
  box-shadow: 0 1px #fff;
}

.schedule-timeline-box {
  background-color: #f8f7f2;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.schedule-timeline-img {
  object-fit: cover;
  width: 75%;
  display: block;
}

.story-cover-container {
  background-color: #dfac9280;
  height: auto;
  padding: 9.6rem 8rem;
}

.story-cover-title {
  text-align: center;
  letter-spacing: .1rem;
  margin-bottom: 4.8rem;
}

.story-cover-heading {
  text-transform: uppercase;
  opacity: .5;
  font-size: 4.8rem;
  transition: all 1s ease-in-out;
  transform: translateX(-100%);
}

.story-cover-subheading {
  opacity: .5;
  font-size: 2.4rem;
  font-style: italic;
  transition: all 1.5s ease-in-out;
  transform: translateX(100%);
}

.story-cover-gallery {
  grid-template-columns: 1fr 1.27fr 1fr;
  column-gap: 4.8rem;
  width: 100%;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  display: grid;
  overflow: hidden;
}

.story-cover-gallery-img {
  border-radius: 1rem;
  width: 100%;
}

.story-cover-gallery-left {
  opacity: 0;
  transition: all 1.5s ease-in-out;
  transform: translateX(-150%);
}

.story-cover-gallery-middle {
  opacity: 0;
  transition: all 1.5s ease-in-out;
  transform: translateY(150%);
}

.story-cover-gallery-right {
  opacity: 0;
  transition: all 1.5s ease-in-out;
  transform: translateX(150%);
}

.story-timeline-container {
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem 8rem;
  padding: 9.6rem 3.2rem;
  display: grid;
}

.story-timeline-img-box {
  opacity: .5;
  width: 100%;
  transition: all 1s ease-in-out;
  position: relative;
  bottom: -5dvh;
}

.story-timeline-img {
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  display: block;
}

.story-timeline-content {
  opacity: .5;
  transition: all 1s ease-in-out;
  position: relative;
  bottom: -5dvh;
}

.story-timeline-content h2 {
  color: #d47b50;
  font-size: 2.4rem;
  font-weight: 400;
}

.story-timeline-content h3 {
  margin-bottom: 2.4rem;
  font-size: 3.2rem;
  font-weight: 500;
}

.story-timeline-content p {
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 1.4em;
}

.story-timeline-content p:last-child {
  margin-bottom: 0;
}

.story-timeline-spacer {
  opacity: .5;
  background-color: #c6754d;
  grid-column: 1 / -1;
  width: .2rem;
  height: 12.8rem;
  transition: all 1s ease-in-out;
  position: relative;
  bottom: -5dvh;
}

.travel {
  background-color: #50685280;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: auto;
  padding: 30dvh 3.2rem;
  display: flex;
}

.section-travel-accommodations-container {
  text-wrap: balance;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.travel h2 {
  color: #3e5043;
  text-transform: uppercase;
  letter-spacing: .025em;
  margin-bottom: 1.6rem;
  font-size: 3rem;
}

.travel p {
  text-wrap: balance;
  color: #3e5043;
  font-size: 1.8rem;
}

.airplanes-container {
  transition: left .5s ease-in-out;
  position: relative;
  left: -100%;
}

.airplanes-container.animate {
  left: 0;
}

.accommodations {
  background-color: #bdc0a180;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: auto;
  padding: 30dvh 3.2rem;
  display: flex;
}

.accommodations h2 {
  color: #8a9480;
  text-transform: uppercase;
  letter-spacing: .025em;
  margin-bottom: 1.6rem;
  font-size: 3rem;
}

.accommodations p {
  color: #8a9480;
  font-size: 1.8rem;
}

.btn-book:is(:link, :visited) {
  color: #8a9480;
  border: 1px solid #8a9480;
  border-radius: 100px;
  padding: .8rem 1.6rem;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .3s;
  animation: 1s infinite wiggle;
}

.btn-book:is(:hover, :focus-visible) {
  color: #edf3e7;
  background-color: #a0b9a2;
  animation: none;
}

.btn-book:active {
  box-shadow: inset 0 2px 5px #00000040;
}

.faqs-section-container {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8rem;
  padding: 9.6rem 3.2rem;
  display: grid;
}

.faq-items-container {
  flex-direction: column;
  gap: 2.4rem;
  display: flex;
}

.faq-item {
  border: .5px solid #dddcd7;
  border-radius: .2rem;
  box-shadow: 0 0 3px #00000009;
}

.faq-item-title {
  color: #6b7280;
  cursor: pointer;
  background-color: #8080800d;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem;
  font-size: 2.4rem;
  display: flex;
}

.faq-item-title:is(:hover, :focus-visible) {
  background-color: #e8e8e878;
}

.faq-item-title.active {
  color: #333;
}

.faq-item-content {
  padding: 2.4rem;
  font-size: 1.6rem;
  display: none;
}

.faq-item-content.active {
  display: block;
}

.faq-item-content li {
  margin-bottom: 1.6rem;
}

.faq-item-content li:last-child {
  margin-bottom: 0;
}

.faq-item-content a:is(:link, :visited) {
  color: #4286d2;
}

.faq-item-content a:is(:hover, :active, :focus-visible) {
  color: #86bbf8;
}

.faq-schedule-link {
  --bg: radial-gradient(40% 50% at center 100%, #5e5e5e80, transparent), radial-gradient(80% 100% at center 120%, #b3b3b31a, transparent), #5e5e5e;
  background: var(--bg);
  color: #d5a153;
  cursor: pointer;
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 1.4rem;
}

.faqs-title-container {
  flex-direction: column;
  gap: 3.2rem;
  display: flex;
}

.faqs-section-title {
  font-size: 8rem;
  line-height: 1em;
}

.registry-cover {
  flex-direction: column;
  display: flex;
}

.registry-img-box {
  position: relative;
}

.registry-img {
  object-fit: cover;
  filter: brightness(80%);
  grid-column: 1 / -1;
  width: 100%;
  display: block;
}

.registry-cover-title {
  text-transform: uppercase;
  color: #f6f6f6;
  font-size: 4.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.registry-links {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.registry-card-original:is(:link, :visited) {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  opacity: .8;
  background-color: #4a738b80;
  border-right: .75px solid #4a738b;
  place-self: center;
  width: 100%;
  padding: 4.8rem 6.4rem;
  font-size: 2.4rem;
  text-decoration: none;
  transition: all .3s;
}

.registry-card-original:last-child {
  border-right: none;
}

.registry-card-original:is(:hover, :active, :focus-visible) {
  opacity: 1;
}

.section-vips {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding: 9.6rem;
  display: flex;
}

.section-vips-container {
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 3.2rem;
  max-width: 700px;
  display: grid;
}

.section-vips h2 {
  text-align: center;
  font-size: 8rem;
}

.vip-card {
  text-align: center;
  border: .75px solid #333;
  max-width: 100%;
}

.vip-card-img-container {
  width: 100%;
}

.vip-card img {
  object-fit: cover;
  width: 100%;
  display: block;
}

.vip-card-text {
  flex-direction: column;
  gap: .8rem;
  padding: 1.4rem;
  display: flex;
}

.vip-card h3 {
  font-size: 3rem;
}

.vip-card p {
  font-size: 2rem;
}

.footer-container {
  background-color: #f8f7f2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding: 9.6rem 3.2rem;
  display: flex;
}

.luna-date-container {
  flex-direction: column;
  gap: 3rem;
  display: flex;
}

.luna-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  display: flex;
}

.luna-img-box {
  width: 20%;
  position: relative;
}

.lottie {
  pointer-events: none;
  z-index: 2;
  position: absolute;
}

.luna-img {
  width: 100%;
  transition: all .3s;
  animation: 1s infinite wiggle;
  display: block;
}

.luna-img:is(:hover, :focus-visible) {
  animation: none;
}

.footer-message {
  text-align: center;
  text-wrap: balance;
  font-size: 2.4rem;
}

.date-timer-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .8rem;
  display: flex;
}

.footer-date {
  color: #ba945c;
  text-align: center;
  margin-bottom: .8rem;
  font-size: 6.4rem;
  font-weight: 500;
}

.countdown-timer {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}

.footer-copyright {
  text-align: center;
  font-size: 1.6rem;
}
/*# sourceMappingURL=index.517fc5d5.css.map */
